import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs:['en','fr'],
    lng: window.navigator.language ,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    }, 
    detection:{
      order: ['navigator','path','cookie','querystring','localStorage', 'sessionStorage', 'htmlTag', 'subdomain'],
      caches: ['cookie'],
    },
    backend:{
      loadPath:'/locales/{{lng}}/translation.json'
    },
    react:{
      useSuspense:false
    }
    
});

export default i18n