import React , {useState , useEffect } from 'react'
import headerPicture from '../../assets/header1.jpg'
import agriCulture from '../../assets/agriculture.png'
import industrie from '../../assets/industrie.png'
import environment from '../../assets/environment.png'
import electronic from '../../assets/electronic.png'
import internetOfThings from '../../assets/internet-of-things.png'
import intelligenceArt from '../../assets/artificial-intelligence.png'
import threeD from '../../assets/3d.png'
import robotique from'../../assets/robot.png'
import indus from '../../assets/indus.png'
import webDev from '../../assets/webdeveloper.png'
import mobileDev from '../../assets/mobile-development.png'
import uxui from '../../assets/uiux-design.png'
import total from '../../assets/total.png'
import apia from '../../assets/apia.png'
import iot from '../../assets/iot.png'
import word from '../../assets/wsa.png'
import giz from '../../assets/giz.png'
import molka from '../../assets/molka.png'
import smartSS from '../../assets/smartSS.png'
import soie from '../../assets/soie.png'
import medisail from '../../assets/medisail.png'
import technoprest from '../../assets/technoprest.png'
import atelierS from '../../assets/2as.png'
import zodiac from '../../assets/zodiac.png'
import mbG from '../../assets/mbG.png'
import goldina from '../../assets/goldina.png'
import marin from '../../assets/marin.png'
import delice from '../../assets/delice.png'
import bipole from '../../assets/bipole.png'
import poulina from '../../assets/poulina.png'
import paf from '../../assets/paf.png'
import iconAgri from '../../assets/iconagriculture.png'
import iconIndus from '../../assets/iconindustrie.png'
import iconCity from '../../assets/iconcity.png'
import agricultureHover from '../../assets/agricultureHover.png'
import industrieHover from '../../assets/industrieHover.png'
import environmentHover from '../../assets/environmentHover.png'
import headerMobile from '../../assets/headerMobile.png'

import {Container , Row ,Col , Carousel} from 'react-bootstrap'
import {Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function Landing() {

  const textAgriNormal=()=>{
    return (
      <div><h4 className="text-img-samrtSolutions">
        SMART <br/> AGRICULTURE
      </h4></div>
    )
  }
  const textAgriHover=()=>{
    return (
      <div> <img className="iconAgri" src={iconAgri} alt=""/> <h4 className="text-img-samrtSolutions">
        SMART <br/> AGRICULTURE
      </h4></div>
   )
  }
  const textIndusNormal=()=>{
    return (
      <div><h4 className="text-img-samrtSolutions ms-4 text-center">
        SMART <br/> INDUSTRY
      </h4></div>
    )
  }
  const textIndusHover=()=>{
    return (
      <div> <img className="iconIndus" src={iconIndus} alt=""/> <h4 className="text-img-samrtSolutions ms-4 text-center">
        SMART <br/> INDUSTRY
      </h4></div>
   )
  }
  const textCityNormal=()=>{
    return (
      <div><h4 className="text-img-samrtSolutions ms-5 text-center">
        SMART <br/> CITY
      </h4></div>
    )
  }
  const textCityHover=()=>{
    return (
      <div> <img className="iconCity" src={iconCity} alt=""/> <h4 className="text-img-samrtSolutions ms-5 text-center">
        SMART <br/> CITY
      </h4></div>
   )
  }

    const [showIconAgri , setShowIconAgri ] = useState(textAgriNormal)
    const [agricultureImg , setAgricultureImg] = useState(agriCulture)
    const [showIconIndus , setShowIconIndus ] = useState(textIndusNormal)
    const [industrieImg , setIndustrieImg ] = useState(industrie)
    const [showIconCity , setShowIconCity ] = useState(textCityNormal)
    const [environmentImg , setEnvironmentImg] = useState(environment)
    const {t} = useTranslation();

    // console.log(window.navigator.language)
    // const boxSmartSolution = useRef(null);
    const boxSmartSolution = React.createRef()  

    const handleClick=()=> {
      window.scrollTo(0, boxSmartSolution.current.offsetTop)
    }


    const handleMouseEnterAgri = e => {
      setAgricultureImg(agricultureHover)
      setShowIconAgri(textAgriHover)
    }
    const handleMouseLeaveAgri = e => {
      setAgricultureImg(agriCulture)
      setShowIconAgri(textAgriNormal)
    }

    const handleMouseEnterIndus = e => {
      setIndustrieImg(industrieHover)
      setShowIconIndus(textIndusHover)
    }
    const handleMouseLeaveIndus = e => {
      setIndustrieImg(industrie)
      setShowIconIndus(textIndusNormal)
    }

    const handleMouseEnterCity = e => {
      setEnvironmentImg(environmentHover)
      setShowIconCity(textCityHover)
    }
    const handleMouseLeaveCity = e => {
      setEnvironmentImg(environment)
      setShowIconCity(textCityNormal)
    }


    const [isMobile, setIsMobile] = useState(false)

    const updateDimensions = () => {
      if (document.documentElement.clientWidth <= 450) {
        setIsMobile(true);
      }else if (document.documentElement.clientWidth >= 450) {
          setIsMobile(false)  
      }
    }
  
    useEffect(() => {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
    },[isMobile])


    

    return (
        <section className="main-box-landing-page">
          {
            isMobile ? (<div>
                <Container className="header"> 
                  <figure className="position-relative">
                      <img className="picture img-fluid" src={headerMobile} alt="" />
                      <figcaption className="over-text-header-mobile text-center"> 
                          <h1 className="title-header-mobile" style={{zIndex:2}}> Leader en IoT services </h1>
                          <p className="text-header-mobile"> 
                          SMART LOGGER est une start up spécialisée dans le développement <br/> des solutions innovantes basées sur l'utilisation de la technologie <br/>'internet des objets'
                          </p>
                          <button className="btn-decouvrir-mobile" onClick={()=>handleClick()}>
                            Découvrir
                          </button>
                      </figcaption>
                  </figure>
                </Container>

                <div className="box-smartSolutions-mobile" ref={boxSmartSolution}>
                  <div className="text-center">
                    <p className="title-smartSolutions-mobile">
                    SMART SOLUTIONS FOR A DIGITAL FUTURE
                  </p>
                  </div>
                  
                  <p className="text-smartSolutions-mobile ms-3">
                    Nous innovons pour satisfaire nos clients ! <br/> 
                    SMART LOGGER présente plusieurs solutions innovantes au service de l’industrie, l’agriculture, et l’environnement.
                  </p>
                   <Carousel className="carousel-smartSolutions" variant="light">
                      <Carousel.Item className="img-smartSolutions-mobile text-center">
                      <Link to="/agriculture">
                        <img className="img-smartSolutions-mobile" src={agricultureHover} alt="" />
                        <Carousel.Caption className="box-text-img-mobile-SmartSol text-center">
                         <img className="" src={iconAgri} alt=""/> 
                         <h4 className="text-agri-samrtSolutions-mobile">
                          SMART <br/> AGRICULTURE
                          </h4>
                        </Carousel.Caption>
                        </Link>
                      </Carousel.Item>
                      <Carousel.Item className="img-smartSolutions-mobile text-center">
                      <Link to="/industrie">
                        <img className="img-smartSolutions-mobile"  src={industrieHover} alt="" />
                        <Carousel.Caption className="box-text-img-mobile-SmartSol text-center">
                        <img className="" src={iconIndus} alt=""/> 
                        <h4 className="text-img-samrtSolutions-mobile text-center">
                          SMART <br/> INDUSTRY
                        </h4>
                        </Carousel.Caption>
                        </Link>
                      </Carousel.Item>
                      <Carousel.Item className="img-smartSolutions-mobile text-center">
                      <Link to="/collect">
                        <img className="img-smartSolutions-mobile"  src={environmentHover} alt="" />
                        <Carousel.Caption className="box-text-img-mobile-SmartSol text-center">
                        <img className="" src={iconCity} alt=""/> 
                        <h4 className="text-img-samrtSolutions-mobile text-center">
                          SMART <br/> CITY
                        </h4>
                        </Carousel.Caption>
                        </Link>
                      </Carousel.Item>
                    </Carousel>
              </div>


              <div className="box-expertise">
                <Container className="text-center">
                  <h5 className="title-expertise" >
                    NOTRE EXPERTISE
                  </h5>
                  <Row className="mt-4">
                    <Col  className="bloc-expertise-mobile ms-2">
                        <img  className="img-expertise-mobile ms-1" src={electronic} alt=""/>
                      <p className="text-expertise-mobile  long-word">CONCEPTION ELECTRONIQUE </p>
                    </Col>
                    <Col  className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1" src={internetOfThings} alt=""/>
                      <p className="text-expertise-mobile"> INTERNET DES OBJETS </p>
                    </Col>
                    </Row>
                    <Row className="mt-4">
                    <Col  className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1" src={intelligenceArt} alt=""/>
                      <p className="text-expertise-mobile long-word"> INTELLIGENCE ARTIFICIELLE </p>
                    </Col>
                    <Col  className="bloc-expertise-mobile ms-2">
                      <img  className="img-expertise-mobile ms-1" src={threeD} alt=""/>
                      <p className="text-expertise-mobile short-word"> IMPRESSION 3D </p>
                    </Col>
                    </Row>
                    <Row className="mt-4">
                    <Col  className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1"  src={robotique} alt=""/>
                      <p className="text-expertise-mobile short-word"> ROBOTIQUE </p>
                    </Col>
                    <Col className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1" src={indus} alt=""/>
                      <p className="text-expertise-mobile short-word"> INDUSTRIE 4.0</p>
                    </Col>
                   </Row>
                  <Row className="mt-4 ">
                    <Col  className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1"  src={webDev} alt=""/>
                      <p className="text-expertise-mobile"> DÉVELOPPEMENT WEB </p>
                    </Col>
                    <Col className="bloc-expertise-mobile ms-2">
                      <img className="img-expertise-mobile ms-1" src={mobileDev} alt=""/>
                      <p className="text-expertise-mobile long-word"> DÉVELOPPEMENT MOBILE </p>
                    </Col>
                    </Row>
                    <div className="pb-5">
                      <div  className="bloc-UXUI-mobile mt-4 ">
                        <img className="img-expertise-mobile ms-2" src={uxui} alt=""/>
                        <p className="text-expertise-mobile short-word"> UI/UX DESIGN </p>
                      </div>
                    </div>  
                </Container>
              </div>

              <div className="box-nos-references ">
                <Container className="text-center container-nos-references">
                  <h5 className="title-reference-mobile" >
                    NOS RÉFÉRENCES
                  </h5>
                  <div className="logos-references">
                  <Carousel variant="light">
                      <Carousel.Item>
                        <img className="total-mobile" src={total} alt="" />
                        <img className="apia-mobile ms-3" src={apia} alt="" />
                        <img className="iot-mobile ms-3" src={iot} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img className="word-mobile " src={word} alt="" />
                        <img className="giz-mobile ms-3" src={giz} alt="" />
                        <img className="medisail-mobile ms-3" src={medisail} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img className="soie-mobile " src={soie} alt="" />
                        <img className="smartSS-mobile ms-2" src={smartSS} alt="" />
                        <img className="molka-mobile ms-2" src={molka} alt="" />
                        <img className="bipole-mobile ms-2" src={bipole} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img className="poulina-mobile " src={poulina} alt="" />
                        <img className="zodiac-mobile ms-2" src={zodiac} alt="" />
                        <img className="atelierS-mobile ms-2" src={atelierS} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                      <img className="technoprest-mobile" src={technoprest} alt="" />
                        <img className="paf-mobile ms-2" src={paf} alt="" /> 
                      <img className="delice-mobile ms-2" src={delice} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img className="marin-mobile " src={marin} alt="" />
                        <img className="goldina-mobile ms-2" src={goldina} alt="" />
                        <img className="mbg-mobile ms-2" src={mbG} alt="" />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Container>  
              </div>

            </div>) 
            : (<div>
                <Container className="header"> 
                <figure className="position-relative">
                      <img className="picture img-fluid" src={headerPicture} alt="" />
                      <figcaption className="over-text-header"> 
                          <h1 className="title-header" style={{zIndex:2}}>
                             {/* {t('leadertitle')}  */}
                             Leader en IoT services
                          </h1>
                          <p className="text-header"> 
                          SMART LOGGER est une start up spécialisée dans le développement <br/> des solutions innovantes basées sur l'utilisation de la technologie <br/>'internet des objets'
                          </p>
                            <button className="btn-decouvrir" onClick={()=>handleClick()}>
                              Découvrir
                            </button>
                      </figcaption>
                </figure>
              </Container>

              <div className="box-smartSolutions" ref={boxSmartSolution}>
                  <p className="title-smartSolutions">
                    SMART SOLUTIONS FOR A DIGITAL FUTURE
                  </p>
                  <p className="text-smartSolutions">
                    Nous innovons pour satisfaire nos clients ! <br/> 
                    SMART LOGGER présente plusieurs solutions innovantes au service de l’industrie, l’agriculture, et l’environnement.
                  </p>
                  <Container className="mt-5 mb-5 box-img-smartSolutions">
                      <Row className="row-col">
                        <Col sm={4} className="col-smartSolutions">
                        <Link to="/agriculture">
                          <figure className="position-relative fig-smartSol" onMouseEnter={handleMouseEnterAgri}
                            onMouseLeave={handleMouseLeaveAgri} >
                            <img className=" img-fluid" src={agricultureImg} alt="" />
                            <figcaption className="over-text-smartSolution text-center" >
                              {showIconAgri } 
                            </figcaption>
                          </figure>
                          </Link>
                        </Col>
                        <Col sm={4} className="col-smartSolutions">
                        <Link to="/industrie">
                        <figure className="position-relative fig-smartSol" onMouseEnter={handleMouseEnterIndus}
                            onMouseLeave={handleMouseLeaveIndus}>
                            <img className=" img-fluid" src={industrieImg} alt="" />
                            <figcaption className="over-text-smartSolution text-center">
                              {showIconIndus}
                            </figcaption>
                          </figure>
                          </Link>
                        </Col>
                        <Col sm={4} className="col-smartSolutions ">
                        <Link to="/collect">
                        <figure className="position-relative fig-smartSol " onMouseEnter={handleMouseEnterCity}
                            onMouseLeave={handleMouseLeaveCity}>
                            <img className="img-fluid" src={environmentImg} alt="" />
                            <figcaption className="over-text-smartSolution text-center">
                              {
                                showIconCity
                              }
                            </figcaption>
                          </figure>
                          </Link>
                        </Col>
                      </Row>
                  </Container>
              </div>

              <div className="box-expertise">
                <Container className="text-center">
                  <h5 className="title-expertise" >
                    NOTRE EXPERTISE
                  </h5>
                  <Row className="mt-5">
                    <Col sm className="bloc-expertise">
                        <img  className="img-expertise" src={electronic} alt=""/>
                      <p className="text-expertise"> CONCEPTION ELECTRONIQUE </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise" src={internetOfThings} alt=""/>
                      <p className="text-expertise"> INTERNET DES OBJETS </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise" src={intelligenceArt} alt=""/>
                      <p className="text-expertise"> INTELLIGENCE ARTIFICIELLE </p>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col sm className="bloc-expertise">
                      <img  className="img-expertise" src={threeD} alt=""/>
                      <p className="text-expertise short-word"> IMPRESSION 3D </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise robot"  src={robotique} alt=""/>
                      <p className="text-expertise short-word"> ROBOTIQUE </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise" src={indus} alt=""/>
                      <p className="text-expertise short-word"> INDUSTRIE 4.0</p>
                    </Col>
                  </Row>
                  <Row className="mt-5 last-bloc">
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise"  src={webDev} alt=""/>
                      <p className="text-expertise"> DÉVELOPPEMENT WEB </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise" src={mobileDev} alt=""/>
                      <p className="text-expertise"> DÉVELOPPEMENT MOBILE </p>
                    </Col>
                    <Col sm className="bloc-expertise">
                      <img className="img-expertise" src={uxui} alt=""/>
                      <p className="text-expertise short-word"> UI/UX DESIGN </p>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="box-nos-references">
                <Container className="text-center container-nos-references">
                  <h5 className="title-references" >
                    NOS RÉFÉRENCES
                  </h5>
                  <div className="logos-references">
                  <Carousel variant="dark">
                      <Carousel.Item>
                        <img className="logo-refer total" src={total} alt="" />
                        <img className="logo-refer apia" src={apia} alt="" />
                        <img className="logo-refer iot" src={iot} alt="" />
                        <img className="logo-refer word" src={word} alt="" />
                        <img className="logo-refer giz" src={giz} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                      <img className="logo-refer medisail" src={medisail} alt="" />
                        <img className="logo-refer soie" src={soie} alt="" />
                        <img className="logo-refer smartSS" src={smartSS} alt="" />
                        <img className="logo-refer molka" src={molka} alt="" />
                        <img className="logo-refer poulina" src={poulina} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                      <img className="logo-refer zodiac" src={zodiac} alt="" />
                        <img className="logo-refer atelierS" src={atelierS} alt="" />
                        <img className="logo-refer technoprest" src={technoprest} alt="" />
                        <img className="logo-refer paf" src={paf} alt="" />
                      </Carousel.Item>
                      <Carousel.Item>
                      <img className="logo-refer delice" src={delice} alt="" />
                        <img className="logo-refer marin" src={marin} alt="" />
                        <img className="logo-refer goldina" src={goldina} alt="" />
                        <img className="logo-refer mbg" src={mbG} alt="" />
                        <img className="logo-refer bipole" src={bipole} alt="" />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Container>  
              </div>

              {/* <div className="box-trophe">
                  <Carousel className="carousel-trophe" variant="light">
                      <Carousel.Item className="img-trophe">
                        <img className="img-trophe" src={trophe1} alt="" />
                        <Carousel.Caption>
                          <h3 className="title-trophe">Nos TROPHÉES</h3>
                          <hr></hr>
                          <p className="text-trophe"><span className="prix"> TROISIÈME PRIX DU CONCOURS </span> <br/> national IoT Tunisia 2018.</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    
                      <Carousel.Item className="img-trophe">
                        <img className="img-trophe"  src={trophe4} alt="" />
                        <Carousel.Caption>
                          <h3 className="title-trophe">Nos TROPHÉES</h3>
                          <hr></hr>
                          <p className="text-trophe"><span className="prix"> PREMIER PRIX DU CHALLENGE </span> <br/>startupper of the year by total .</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item className="img-trophe">
                        <img className="img-trophe"  src={trophe6} alt="" />
                        <Carousel.Caption>
                          <h3 className="title-trophe">Nos TROPHÉES</h3>
                          <hr></hr>
                          <p className="text-trophe"><span className="prix">NOTRE PARTICIPATION </span> <br/>au programme STARTI-4</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </div> */}
            </div>)
          }
           
        </section>
    )
}

export default Landing
