import React , {useState,useEffect} from 'react'
import LogoSmartLogger  from '../../assets/smartloggerlogotransparant.png'
import {Container} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function Footer() {

    const [isMobile, setIsMobile] = useState(false)
    const {t} = useTranslation();


    const updateDimensions = () => {
      if (document.documentElement.clientWidth <= 450) {
        setIsMobile(true);
      }else if (document.documentElement.clientWidth >= 450) {
          setIsMobile(false)  
      }
    }
  
    useEffect(() => {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
  
    },[isMobile])

    return (
        <div className="main-box-footer ">
            {isMobile ? (<div>
                <div className="text-center">
                   <img className="logo-footer mt-5" src={LogoSmartLogger} alt="" />
                   <p className="text-descreption-mobile mt-4">
                     SMART LOGGER est une startup nouvellement dévoilée par une équipe d'ingénieurs Tunisiens. Grâce à son staff multidisciplinaire et à ses rapports étroits avec ses partenaires, elle fournit des solutions de gestion innovantes au service de l’agriculture, et l'industrie .
                   </p>
                </div>

                <div className="mt-4">
                   <div className="lien">
                      <p className="text-lien-mobile"> <Link to="/"> 
                        {/* {t("Acceuil")} */}
                        ACCEUIL
                      </Link></p>
                      <p className="text-lien-mobile"> <Link to="/solutions"> 
                        {/* {t("Solutions")}  */}
                        SOLUTIONS
                      </Link></p>
                      <p className="text-lien-mobile"> <Link to="/a-propos">
                         {/* {t("aPropos")} */}
                         À PROPOS
                      </Link></p>
                      <p className="text-lien-mobile"><a href='https://www.smartlogger.tn/customer-space/'>
                         {/* {t("espaceClient")} */}
                         ESPACE CLIENT
                      </a></p>
                      <p className="text-lien-mobile"> <Link to="/contact">
                         {/* {t("contact")} */}
                         CONTACT
                      </Link></p>
                   </div>

                   <div className="contacte-mobile">
                      <p className="text-contact-footer-mobile">NOUS CONTACTEZ</p>
                      <p className="text-contact-footer-mobile"> Adresse: Atelier N°1 Technopole de Sousse Novation City , Tunisie</p>
                      <p className="text-contact-footer-mobile">Téléphone:+(216) 52 017 986</p>
                      <p className="text-contact-footer-mobile">Email: contact@smartlogger.tn</p>
                   </div>
                </div>

                <div className="text-center mt-1">
                    <h5 className="smart-logger p-3" >
                       SMARTLOGGER 2021 © Tous droits réservés
                    </h5>
                </div>

            </div>) 
            
            : (<div>
                <div className="descreption">
                    <img className="logo-footer" src={LogoSmartLogger} alt="" />
                    <p className="text-descreption ">
                    SMART LOGGER est une startup nouvellement dévoilée par une équipe d'ingénieurs Tunisiens. Grâce à son staff multidisciplinaire et à ses rapports étroits avec ses partenaires, elle fournit des solutions de gestion innovantes au service de l’agriculture, et l'industrie .
                    </p>
                </div>
                   <div className="lien">
                      <p className="text-lien"> <Link to="/">ACCEUIL</Link></p>
                      <p className="text-lien"> <Link to="/solutions">SOLUTIONS</Link></p>
                      <p className="text-lien"> <Link to="/a-propos"> À PROPOS</Link></p>
                      <p className="text-lien"><a href='https://www.smartlogger.tn/customer-space/'>ESPACE CLIENT </a></p>
                      <p className="text-lien"> <Link to="/contact"> CONTACT</Link></p>
                   </div>

                   <div className="contacte">
                      <p className="text-contact-footer">NOUS CONTACTEZ</p>
                      <p className="text-contact-footer">Adresse: Atelier N°1 Technopole de Sousse Novation City , Tunisie</p>
                      <p className="text-contact-footer">Téléphone:+(216) 52 017 986</p>
                      <p className="text-contact-footer">Email: contact@smartlogger.tn</p>
                   </div>

                   <div className="joinUs">
                      <p className="text-contact-footer  rejoindre">Nous Rejoindre</p>
                      <a href="https://www.facebook.com/SMART.LOGGER.TN/"><i className="bi bi-facebook icons me-3"></i></a>
                      <a href="https://twitter.com/SMART_LOGGER"><i className="bi bi-twitter icons me-3"></i></a>
                      <a href="https://www.linkedin.com/company/smart-logger"><i className="bi bi-linkedin icons me-3"></i></a>
                      <a href="https://www.youtube.com/channel/UC9y0IptiOP64iCvh51C3opA"><i className="bi bi-youtube icons"></i></a>
                   </div>

                   <Container className="text-center">
                     <h5 className="smart-logger p-3" >
                       SMARTLOGGER 2021 © Tous droits réservés
                     </h5>
                   </Container>
            </div>)}

           
        </div>
    )
}

export default Footer
