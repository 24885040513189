import React ,{useEffect,useState} from 'react'
import contact from '../../assets/contact1.png'
import { Container , Row , Col} from 'react-bootstrap'
import {TextField  } from '@mui/material'
import contactMobile from '../../assets/contactMobile.jpeg'
import axios from 'axios'
import { useTranslation } from 'react-i18next';



function Contact() {

    const [isMobile, setIsMobile] = useState(false)
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")
    const [success,setSuccess] = useState("")
    const [error,setError] = useState("")
    const {t}=useTranslation();

    
    const sendEmail= ()=>{
      const obj={
        "name": name,
        "from": email,
        "sendTo": "contact@smartlogger.tn",
        "subject": "contacter SMART LOGGER",
        "message":message
      }
      axios.post('https://smartlogger.ovh/email',obj).then((res)=>{
       setSuccess("Merci , votre message est envoyé !")
      })
      .catch(err=>{
        setError("Une erreur inattendue est survenue, essayer à nouveau plus tard.")
          console.log(err)
      })
    }
    
    const updateDimensions = () => {
      if (document.documentElement.clientWidth <= 450) {
        setIsMobile(true);
      }else if (document.documentElement.clientWidth >= 450) {
          setIsMobile(false)  
      }
    }
  
    useEffect(() => {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
    },[isMobile])

    return (
        <div>
          {
            isMobile ? 
            (<div>
              <Container className="header-contact"> 
                <figure className="position-relative">
                  <img className="picture-contact img-fluid" src={contactMobile} alt="" />
                  <Container className="container-text ">
                      <figcaption className="over-text-contact-mobile">
                            <h3 className="title-contact-mobile">
                              Parlez-nous de votre besoin
                            </h3>
                            <p className="text-contact-mobile mt-2">
                              N'hésitez pas à nous contacter pour toutes informations complémentaires. 
                              Nous serions très heureux de recevoir et traiter votre consultation.
                            </p>
                            <hr className="hr" /> 
                          
                            <p className="text-NousContacter-mobile mt-5"> 
                              Contactez Nous
                            </p>
                            <TextField className="input-mobile mt-3" label="Nom" placeholder="Nom" focused onChange={(e)=>setName(e.target.value)}/>
                            <TextField className="input-mobile mt-5" label="Email" placeholder="Email" focused onChange={(e)=>setEmail(e.target.value)}/>
                            <TextField className="input-mobile mt-5" label="Message" placeholder="Message" focused onChange={(e)=>setMessage(e.target.value)}/>
                            <button className="btn-envoyer-mobile mt-4 " onClick={sendEmail}>
                                {t("envoyer")}
                            </button>
                            <p>{success}</p>
                            <p>{error}</p>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
            </div>) : 
            (<div>
              <Container className="header-contact"> 
                <figure className="position-relative">
                  <img className="picture-contact img-fluid" src={contact} alt="" />
                  <Container className="container-text ">
                      <figcaption className="over-text-contact"> 
                      <Row>
                          <Col sm={5}>
                              <h3 className="title-contact">
                                Parlez-nous de votre besoin
                              </h3>
                              <p className="text-contact mt-3">
                              N'hésitez pas à nous contacter pour toutes informations complémentaires. 
                              Nous serions très heureux de recevoir et traiter votre consultation.
                              </p>
                              <hr className="hr" /> 
                          </Col>
                          <Col sm={7}>
                              <p className="text-NousContacter"> 
                                Contactez Nous
                              </p> 
                              <TextField className="input " label="Nom" placeholder="Nom" focused onChange={(e)=>setName(e.target.value)}/>
                              <TextField className="input " label="Email" placeholder="Email" focused onChange={(e)=>setEmail(e.target.value)}/>
                              <TextField className="input " label="Message" placeholder="Message" focused onChange={(e)=>setMessage(e.target.value)}/>
                              <button className="btn-envoyer " onClick={sendEmail}>
                              {/* {t("envoyer")} */}
                              Envoyer
                              </button>
                              <p className="text-success mt-3">{success}</p>
                              <p className="text-error mt-3">{error}</p>
                          </Col>
                      </Row>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
              <iframe id='mapFrame' className="mapFrame"
                  src="https://maps.google.com/maps?q=Business%20Incubator%20-%20Sousse%20pole&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0" allowFullScreen>
              </iframe>
            </div>)
          }
              
        </div>
    )
}

export default Contact
