import React, { useState, useEffect } from 'react'
import agriculture from '../../assets/agriculture-page1.png'
import agricultureMobile from '../../assets/agricultureMobile.png'
import smartIrr from '../../assets/smart-irrigation.png'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';


function Agriculture() {

  const [isMobile, setIsMobile] = useState(false)
  const {t} = useTranslation();


  const updateDimensions = () => {
    if (document.documentElement.clientWidth <= 450) {
      setIsMobile(true);
    }else if (document.documentElement.clientWidth >= 450) {
        setIsMobile(false)  
    }
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  },[isMobile])


  return (
    <div >
      {isMobile ? (<div> 
        <div className="header-agriculture-mobile" >
          <figure className="position-relative">
            <img className="picture-agriculture img-fluid" src={agricultureMobile} alt="" />
            <Container className="container-text">
              <figcaption className="over-text-agri-mobile">
              <div className="text-center">
                <img className="logo-agri" src={smartIrr} alt="" />
                <h6 className="title-smartIrrigation-mobile" >
                  SMART IRRIGATION
                </h6>
                <h6 className="sous-title-smartIrrigation-mobile">
                  SOLUTION INTELLIGENTE D’IRRIGATION À DISTANCE
                </h6>
                <p className="text-smartIrrigation-mobile mt-3">
                  SMART IRRIGATION consiste à moderniser la gestion des champs irrigués. Le package proposé contient principalement un kit électronique connecté à l’internet capable de commander à distance les vannes et les motopompes d’irrigation via une application mobile. Cet outil permet à l’agriculteur d’épargner le déplacement sur site et il lui permet de définir des temps d’irrigation très précis. En plus nous proposons l’installation des capteurs intelligents pour faire de l’échantillonnage sur des différents niveaux de sols afin d’informer l’agriculteur sur le pourcentage d’humidité du sol. Ceci afin de savoir la quantité d’eau d’irrigation nécessaire.
                </p> 
                </div>
                <div className="box-btn-mobile">
                
                <button className="btn-telecharger-smartIrrigation-mobile">
                  {/* {t("telecharger")} */}
                  Télécharger
                </button>

                <a href='https://www.smartlogger.tn/smart-irrigation-admin/#/login'>
                  <button className="btn-paltadm-smartIrrigation-mobile">
                     Plateforme administrateur
                  </button>
                </a>
                </div>
              </figcaption>
            </Container>
          </figure>
        </div>
      </div>) :
        (<div className="header-agriculture">
          <figure className="position-relative">
            <img className="picture-agriculture img-fluid" src={agriculture} alt="" />
            <Container className="container-text text-center">
              <figcaption className="over-text-agri">
                <img className="logo-agri" src={smartIrr} alt="" />
                <h6 className="title-smartIrrigation" >
                  SMART IRRIGATION
                </h6>
                <h6 className="sous-title-smartIrrigation">
                  SOLUTION INTELLIGENTE D’IRRIGATION À DISTANCE
                </h6>
                <p className="text-smartIrrigation ">
                  SMART IRRIGATION consiste à moderniser la gestion des champs irrigués. Le package proposé contient principalement un kit électronique connecté à l’internet capable de commander à distance les vannes et les motopompes d’irrigation via une application mobile. Cet outil permet à l’agriculteur d’épargner le déplacement sur site et il lui permet de définir des temps d’irrigation très précis. En plus nous proposons l’installation des capteurs intelligents pour faire de l’échantillonnage sur des différents niveaux de sols afin d’informer l’agriculteur sur le pourcentage d’humidité du sol. Ceci afin de savoir la quantité d’eau d’irrigation nécessaire.
                </p>
                <button className="btn-telecharger-smartIrrigation">
                {/* {t("telecharger")} */}
                Télécharger
                </button>
                <a href='https://www.smartlogger.tn/smart-irrigation-admin/#/login'>
                 <button className="btn-paltadm-smartIrrigation">
                   Plateforme administrateur
                 </button>
                </a>
              </figcaption>
            </Container>
          </figure>
        </div>)
      }

    </div>
  )
}

export default Agriculture
