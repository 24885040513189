import React , {useState,useEffect} from 'react'
import {Container} from 'react-bootstrap'
import smartCollect from '../../assets/smartCollect.png'
import collectMobile from '../../assets/collectMobile.png'
import logoSmartCollect from '../../assets/logoSmartCollect.png'
import { useTranslation } from 'react-i18next';


function Collect() {


  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
  
    return (
      <p className="textSmartCollectMobile">
        {isReadMore ? text.slice(0, 503) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...lire plus" : " lire moins"}
        </span>
      </p>
    );
  };

  const [isMobile, setIsMobile] = useState(false)
  const {t} = useTranslation()

  const updateDimensions = () => {
    if (document.documentElement.clientWidth <= 450) {
      setIsMobile(true);
    }else if (document.documentElement.clientWidth >= 450) {
        setIsMobile(false)  
    }
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

  },[isMobile])

    return (
        <div>
          {isMobile ? (<div>
            <Container className="header-collect-mobile"> 
                <figure className="position-relative">
                  <img className="picture-smartCollect img-fluid" src={collectMobile} alt="" />
                  <Container className="container-text">
                      <figcaption className="over-text-smartCollect-mobile"> 
                      <div className="text-center"> 
                        <img className="logo-collect" src={logoSmartCollect} alt="" />
                          <h6 className="title-smartCollect-mobile" >
                            SMART COLLECT
                          </h6> 
                          <h6 className="sous-title-smartCollect-mobile"> 
                          CONVERTIR VOS DÉCHETS MÉNAGERS EN SOURCE DE REVENU !
                          </h6>
                          <ReadMore> 
                            La production annuelle de déchets ménagers en Tunisie est estimée à 3 millions de tonnes/an. 
                            Malheureusement plus que 1,4 million de tonnes/an finissent dans des décharges anarchiques ! 
                            Pour lutter contre la pollution, préserver l’environnement et améliorer les conditions de vie 
                            d’une catégorie marginalisée, les chiffonniers « BERBECHA », nous proposons une solution du tri 
                            précoce de déchets ménagers. Grâce à notre Platform WEB et Mobile SMART COLLECT la récupération 
                            des déchets est devenu un art. Après avoir trié vos déchets selon notre standard, il suffit de faire 
                            un simple clic pour recevoir l’un de nos agents pour récupérer ces produits. Afin d’encourager 
                            et récompenser les utilisateurs de notre plateforme, après la bonne exécution de chaque opération 
                            l'utilisateur reçoit une somme de points MERCI convertible en bon d’achats auprès des grandes surfaces commerciales !  
                                
                          </ReadMore>
                          </div>
                          <div className="box-btn-mobile">
                          <button className="btn-telecharger-smartCollect-mobile">
                          {/* {t("telecharger")} */}
                          Télécharger
                          </button>
                          <button className="btn-paltadm-smartCollect-mobile">
                          Plateforme administrateur
                          </button>
                          </div>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
          </div>) : 
          (<div>
            <Container className="header-collect"> 
                <figure className="position-relative">
                  <img className="picture-smartCollect img-fluid" src={smartCollect} alt="" />
                  <Container className="container-text text-center">
                      <figcaption className="over-text-smartCollect"> 
                        <img className="logo-collect" src={logoSmartCollect} alt="" />
                          <h6 className="title-smartCollect" >
                            SMART COLLECT
                          </h6> 
                          <h6 className="sous-title-smartCollect">
                          CONVERTIR VOS DÉCHETS MÉNAGERS EN SOURCE DE REVENU !
                          </h6>
                          <p className="text-smartCollect">
                          La production annuelle de déchets ménagers en Tunisie est estimée à 3 millions de tonnes/an. 
                          Malheureusement plus que 1,4 million de tonnes/an finissent dans des décharges anarchiques ! 
                          Pour lutter contre la pollution, préserver l’environnement et améliorer les conditions de vie 
                          d’une catégorie marginalisée, les chiffonniers « BERBECHA », nous proposons une solution du tri 
                          précoce de déchets ménagers. Grâce à notre Platform WEB et Mobile SMART COLLECT la récupération 
                          des déchets est devenu un art. Après avoir trié vos déchets selon notre standard, il suffit de faire 
                          un simple clic pour recevoir l’un de nos agents pour récupérer ces produits. Afin d’encourager 
                          et récompenser les utilisateurs de notre plateforme, après la bonne exécution de chaque opération 
                          l'utilisateur reçoit une somme de points MERCI convertible en bon d’achats auprès des grandes surfaces commerciales !       
                          </p>
                          <button className="btn-telecharger-smartCollect">
                          {/* {t("telecharger")} */}
                           Télécharger
                          </button>
                          <button className="btn-paltadm-smartCollect">
                          Plateforme administrateur
                          </button>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
          </div>)}

            
        </div>
    )
}

export default Collect
