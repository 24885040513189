import React ,{useState,useEffect}from 'react'
import ImgApropos from '../../assets/a-propos.png' 
import {Container } from 'react-bootstrap'
import Iconmission from '../../assets/iconmission.png'
import Iconvision from '../../assets/iconvision.png'
import Iconplan from '../../assets/iconplan.png'
import aproposMobile from '../../assets/aproposMobile.png'
import {useTranslation} from 'react-i18next'


function Apropos() {

    const [isMobile, setIsMobile] = useState(false)
    const {t}=useTranslation()

    const updateDimensions = () => {
      if (document.documentElement.clientWidth <= 450) {
        setIsMobile(true);
      }else if (document.documentElement.clientWidth >= 450) {
          setIsMobile(false)  
      }
    }
  
    useEffect(() => {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
  
    },[isMobile])


    return (
        <div>
          {
            isMobile ? (<div>
              <Container className="header-apropos"> 
              <figure className="position-relative">
                 <img className="picture-apropos img-fluid" src={aproposMobile} alt="" />
                 <Container className="container-text ">
                    <figcaption className="over-text-apropos-mobile"> 
                        <h5 className="title-apropos-mobile" >
                        QUI SOMMES NOUS ?
                        </h5> 
                        <p className="text-apropos-mobile mt-3">
                        SMART LOGGER est une startup Tunisienne nouvellement dévoilée par une équipe d’ingénieurs. 
                        Grâce à son staff multidisciplinaire et à ses rapports étroits avec ses partenaires, 
                        elle fournit des solutions technologiques innovantes ayant pour objectif d’accélérer la transformation 
                        digitale de ses clients notamment dans les secteurs industriel, agricole et médical.                       
                        </p>
                        <div className="bloc-mission">
                            <div className="mission-mobile">
                                <div className="d-flex justify-content-start">
                                <div className="align-self-center">
                                  <img className="iconMission-mobile" alt="" src={Iconmission}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                    <p className="text-bloc-mission"> Notre Mission</p>
                                    <p className="text-bloc-mission"> Accélérer la transformation digitale</p>
                                </div>
                                </div>
                            </div>
                            <div className="vision-mobile">
                            <div className="d-flex justify-content-start">
                                <div className="align-self-center">
                                <img className="iconMission-mobile" alt="" src={Iconvision}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                <p className="text-bloc-mission"> Notre Vision</p>
                                <p className="text-bloc-mission"> Rapprocher le futur</p>
                                </div>
                                </div>
                            </div>
                            <div className="plan-mobile">
                            <div className="d-flex justify-content-start">

                                <div className="align-self-center">
                                <img className="iconplanMobile" alt="" src={Iconplan}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                <p className="text-bloc-mission"> Notre Plan </p>
                                <p className="text-bloc-mission"> Intégrer les nouvelles technologies</p>
                                </div>
                                </div>
                            </div>
                        </div>
                  </figcaption>
                </Container>
              </figure>
            </Container>

            </div>) 
            : (<div>
              <Container className="header-apropos"> 
              <figure className="position-relative">
                 <img className="picture-apropos img-fluid" src={ImgApropos} alt="" />
                 <Container className="container-text ">
                    <figcaption className="over-text-apropos"> 
                        <h5 className="title-apropos" >
                        QUI SOMMES NOUS ?
                        </h5> 
                        <p className="text-apropos ">
                        SMART LOGGER est une startup Tunisienne nouvellement dévoilée par une équipe d’ingénieurs. 
                        Grâce à son staff multidisciplinaire et à ses rapports étroits avec ses partenaires, 
                        elle fournit des solutions technologiques innovantes ayant pour objectif d’accélérer la transformation 
                        digitale de ses clients notamment dans les secteurs industriel, agricole et médical.                       
                        </p>
                        <div className="bloc-mission">
                            <div className="mission">
                                <div className="d-flex justify-content-start">
                                <div className="align-self-center">
                                  <img className="iconMission" alt="" src={Iconmission}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                    <p className="text-bloc-mission"> Notre Mission</p>
                                    <p className="text-bloc-mission"> Accélérer la transformation digitale</p>
                                </div>
                                </div>
                            </div>
                            <div className="vision">
                            <div className="d-flex justify-content-start">
                                <div className="align-self-center">
                                <img className="iconMission" alt="" src={Iconvision}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                <p className="text-bloc-mission"> Notre Vision</p>
                                <p className="text-bloc-mission"> Rapprocher le futur</p>
                                </div>
                                </div>
                            </div>
                            <div className="plan">
                            <div className="d-flex justify-content-start">

                                <div className="align-self-center">
                                <img className="iconplan" alt="" src={Iconplan}/>
                                </div>
                                <div className="bloc-text ms-2"> 
                                <p className="text-bloc-mission"> Notre Plan </p>
                                <p className="text-bloc-mission"> Intégrer les nouvelles technologies</p>
                                </div>
                                </div>
                            </div>
                        </div>
                  </figcaption>
                </Container>
              </figure>
            </Container>
            </div>)
          }
             
        </div>
    )
}

export default Apropos
