import './App.css';
import "./Components/Navbar/navbar.css"
import './Components/Landing/landing.css'
import './Components/Footer/footer.css'
import './Components/Agriculture/agriculture.css'
import './Components/Industrie/industrie.css'
import './Components/Collect/collect.css'
import './Components/Apropos/apropos.css'
import './Components/Contact/contact.css'
import {
  // BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NavbarPage from './Components/Navbar/Navbar';
import Landing from './Components/Landing/Landing';
import Footer from './Components/Footer/Footer';
import Agriculture from './Components/Agriculture/Agriculture';
import Industrie from './Components/Industrie/Industrie'
import Collect from './Components/Collect/Collect'
import Solutions from './Components/Solutions/Solutions';
import Apropos from './Components/Apropos/Apropos';
import Contact from './Components/Contact/Contact'
import {HashRouter} from "react-router-dom"



function App() {
  return (
    <HashRouter>
      <NavbarPage />
      <Switch>
        <Route className="landing" exact path="/" component={Landing} />
        <Route exact path="/agriculture" component={Agriculture} />
        <Route exact path="/industrie" component={Industrie} />
        <Route exact path="/collect" component={Collect} />
        <Route exact path="/solutions" component={Solutions} />
        <Route exact path="/a-propos" component={Apropos} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
      <Footer className="footer-app"/>
    </HashRouter>
   
  );
}

export default App;
