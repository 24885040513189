import React, { useState, useEffect } from 'react'
import LogoSmartLogger from '../../assets/smartloggerlogotransparant.png'
import smartLoggerLogoScrolling from '../../assets/logoSLscroll.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Fr',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'En',
    country_code: 'gb',
  },
]


function NavbarPage() {
  const [navbar, setNavbar] = useState(false)
  const [navbarLogo, setNavbarLogo] = useState(LogoSmartLogger)
  const [itemColor, setItemColor] = useState(false)
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const currentLangugeCode = cookies.get('i18next') || window.navigator.language 
  const currentLanguge = languages.find(l=> l.code === currentLangugeCode)
  const {t} = useTranslation();
 

  useEffect(()=>{
    document.body.dir = 'ltr'
  },[currentLanguge])

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    if(isNavCollapsed){
      setNavbar(true)
      setItemColor(true)
      setNavbarLogo(smartLoggerLogoScrolling)
    }else if (!isNavCollapsed){
      setNavbar(false)
      setItemColor(false)
      setNavbarLogo(LogoSmartLogger)
    }
  }

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  }, [])

  // navbar scroll changeItemColor function 
  const changeItemColor = () => {
    if (window.scrollY >= 66) {
      setItemColor(true)
    } else {
      setItemColor(false)
    }
  }

  useEffect(() => {
    changeItemColor()
    window.addEventListener("scroll", changeItemColor)
  }, [])

  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo(smartLoggerLogoScrolling)
    } else {
     setNavbarLogo(LogoSmartLogger)
    }
  }

  useEffect(() => {
    changeLogo()
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo)
  }, [])



  return (
    <nav className={navbar ? "navbar active navbar-expand-lg" : "navbar navbar-expand-lg"}>
      <div className="container-fluid">
        <Link to="/" className="logo-link">
          <img className="logo-transparant" src={navbarLogo} alt="logo" />
        </Link>
        <button className={itemColor ? "custom-toggler navbar-toggler" : "custom-toggler navbar-toggler white"}
          type="button" data-toggle="collapse"
          data-target="#navbarsExample09" aria-controls="navbarsExample09"
          aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
          onClick={handleNavCollapse}>
          &#9776;
        </button>
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
          <ul className="navbar-nav box-items">
            <Link to="/">
              <li className={itemColor ? "nav-item item-active" : "nav-item item"} onClick={handleNavCollapse}>
                {/* {t("Acceuil")} */}
                ACCEUIL
              </li>
            </Link>
            <Link to="/solutions">
              <li className={itemColor ? "nav-item item-active " : "nav-item item"} onClick={handleNavCollapse}>
                {/* {t("Solutions")} */}
                SOLUTIONS
              </li>
            </Link>
            <Link to="/a-propos">
              <li className={itemColor ? "nav-item item-active" : "nav-item item"} onClick={handleNavCollapse}>
                {/* {t("aPropos")} */}
                À PROPOS
              </li>
            </Link>
            <a href="https://www.smartlogger.tn/customer-space/">
              <li className={itemColor ? "nav-item item-active " : "nav-item item"} onClick={handleNavCollapse}>
                {/* {t("espaceClient")} */}
                ESPACE CLIENT
              </li>
            </a>
            <Link to="/contact">
              <li className={itemColor ? "nav-item item-active" : "nav-item item"} onClick={handleNavCollapse}>
                {/* {t("contact")} */}
                CONTACT
              </li>
            </Link> 
              {/* <li>
                <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                    <i className="bi bi-globe"></i>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {languages.map(({ code, name, country_code }) => (
                      <li key={country_code}>
                        <button className='dropdown-item' onClick={()=>(i18next.changeLanguage(code))} disabled={code=== currentLangugeCode}>
                        <span className={`flag-icon flag-icon-${country_code}`} style={{opacity: code === currentLangugeCode ? 0.5 : 1}}></span>
                        <span className='ms-1'>{name}</span>
                      </button>
                      </li>
                    ))}
                 </ul>
                </div>
              </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavbarPage
