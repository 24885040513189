import React , {useEffect,useState} from 'react'
import {Container} from 'react-bootstrap'
import smartControl from '../../assets/smartControl.png'
import logoSmartControl from '../../assets/logoSmartControl.png'
import smartMonitoring from '../../assets/smartMonitoring.png'
import logoSmartMoni from '../../assets/logoSmartMoni.png'
import monitMobile from '../../assets/monitMobile.png'
import controlMobile from '../../assets/controlMobile.png'
import {useTranslation} from "react-i18next"


function Industrie() {

  const [isMobile, setIsMobile] = useState(false)
  const {t}= useTranslation() 

  const updateDimensions = () => {
    if (document.documentElement.clientWidth <= 450) {
      setIsMobile(true);
    }else if (document.documentElement.clientWidth >= 450) {
        setIsMobile(false)  
    }
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

  },[isMobile])


    return (
        <div className="main-box-industrie">
          {
            isMobile ? (<div> 
               <Container className="header-industrie-mobile"> 
                <figure className="position-relative">
                  <img className="picture-smartControl img-fluid" src={controlMobile} alt="" />
                  <Container className="container-text">
                      <figcaption className="over-text-smartControl-mobile"> 
                      <div className="text-center">
                        <img className="logo-indus" src={logoSmartControl} alt="" />
                          <h6 className="title-smartControl-mobile" >
                            SMART CONTROL
                          </h6> 
                          <h6 className="sous-title-smartControl-mobile">
                            CONTRÔLER INTELLIGEMMENT LES ENTREPÔTS FRIGORIFIQUES
                          </h6>
                          <p className="text-smartControl-mobile">
                          SMART CONTROL consiste à sécuriser l’emmagasinage des produits alimentaires dans les entrepôts frigorifiques. 
                          En effet notre kit électronique converti des indicateurs de performances, généralement des grandeurs physiques 
                          telles que la température, le taux d’humidité en des Data Cloud accessibles à distance sur une base de données. 
                          L’utilisateur peut surveiller son espace à distance sur une plateforme web à travers l’internet. En cas d’anomalie, 
                          instantanément il reçoit une alerte afin de réagir le plus rapide possible.                        
                          </p>
                          </div>
                          <div className="box-btn-mobile">
                          <button className="btn-telecharger-smartControl-mobile">
                          {/* {t("telecharger")} */}
                          Télécharger
                          </button>
                          <a href="https://www.smartlogger.tn/smart-control/#/login">
                          <button className="btn-paltadm-smartControl-mobile">
                            Plateforme administrateur
                          </button>
                          </a>
                          </div>
                    </figcaption>
                  </Container>
                </figure>
              </Container>

              <Container className="header-industrie-mobile"> 
                <figure className="position-relative">
                  <img className="picture-smartControl img-fluid" src={monitMobile} alt="" />
                  <Container className="container-text ">
                      <figcaption className="over-text-smartMonit-mobile"> 
                      <div className="text-center">
                        <img className="logo-indus1" src={logoSmartMoni} alt="" />
                          <h6 className="title-smartMonit-mobile" >
                            SMART MONITORING
                          </h6> 
                          <h6 className="sous-title-smartControl-mobile">
                            DIGITALISER ET RELANCER LE DYNAMISME DE L'INDUSTRIE
                          </h6>
                          <p className="text-smartMonit-mobile">
                            L’industrie 4.0 est une réorganisation complète du mode de production avec les outils existants et donnant une plus 
                            grande importance au réseau. Cette nouvelle génération d’usines a pour objectif de relancer le dynamisme de l’industrie. 
                            Notre solution SMART Monitoring est une combinaison de capteurs, automates, Big data, Internet des objets, cloud computing 
                            ayant pour objectif de modernisation de la production, augmentation de la compétitivité, positionnement face aux enjeux de la 
                            mondialisation. La solution contient plusieurs kits électroniques installés soit directement sur les machines principales
                            de productions ou bien au niveau des postes opérateurs. Grace à ces systèmes connectés, nous pouvons extraire en temps réel 
                            des statistiques et des informations jugées nécessaires pour superviser la chaine de production. Tous les Data collectés 
                            peuvent être visualisés en ligne via l’internent soit dans la salle de contrôle soit à l’extérieur de l’usine.                     
                          </p>
                          </div>
                          <div className="box-btn-mobile">
                          <button className="btn-telecharger-smartControl-mobile">
                            {/* {t("telecharger")} */}
                            Télécharger
                          </button>
                          <a href="https://www.smartlogger.tn/smart-monitoring/#/login">
                           <button className="btn-paltadm-smartControl-mobile">
                            Plateforme administrateur
                           </button>
                          </a>
                          </div>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
            </div>) 
            : (<div>

                <Container className="header-industrie"> 
                <figure className="position-relative">
                  <img className="picture-smartControl img-fluid" src={smartControl} alt="" />
                  <Container className="container-text text-center">
                      <figcaption className="over-text-smartControl"> 
                        <img className="logo-indus" src={logoSmartControl} alt="" />
                          <h6 className="title-smartControl" >
                            SMART CONTROL
                          </h6> 
                          <h6 className="sous-title-smartControl">
                            CONTRÔLER INTELLIGEMMENT LES ENTREPÔTS FRIGORIFIQUES
                          </h6>
                          <p className="text-smartControl">
                            SMART CONTROL consiste à sécuriser l’emmagasinage des produits alimentaires dans les entrepôts frigorifiques. 
                            En effet notre kit électronique converti des indicateurs de performances, généralement des grandeurs physiques 
                            telles que la température, le taux d’humidité en des Data Cloud accessibles à distance sur une base de données. 
                            L’utilisateur peut surveiller son espace à distance sur une plateforme web à travers l’internet. En cas d’anomalie, 
                            instantanément il reçoit une alerte afin de réagir le plus rapide possible.                        
                          </p>
                          <button className="btn-telecharger-smartControl">
                            {/* {t("telecharger")} */}
                            Télécharger
                          </button>
                          <a href="https://www.smartlogger.tn/smart-control/#/login">
                           <button className="btn-paltadm-smartControl">
                             Plateforme administrateur
                           </button>
                          </a>
                    </figcaption>
                  </Container>
                </figure>
              </Container>

              <Container className="header-industrie"> 
                <figure className="position-relative">
                  <img className="picture-smartControl img-fluid" src={smartMonitoring} alt="" />
                  <Container className="container-text text-center">
                      <figcaption className="over-text-smartMonit"> 
                        <img className="logo-indus1" src={logoSmartMoni} alt="" />
                          <h6 className="title-smartControl" >
                            SMART MONITORING
                          </h6> 
                          <h6 className="sous-title-smartControl">
                            DIGITALISER ET RELANCER LE DYNAMISME DE L'INDUSTRIE
                          </h6>
                          <p className="text-smartMonit">
                            L’industrie 4.0 est une réorganisation complète du mode de production avec les outils existants et donnant une plus 
                            grande importance au réseau. Cette nouvelle génération d’usines a pour objectif de relancer le dynamisme de l’industrie. 
                            Notre solution SMART Monitoring est une combinaison de capteurs, automates, Big data, Internet des objets, cloud computing 
                            ayant pour objectif de modernisation de la production, augmentation de la compétitivité, positionnement face aux enjeux de la 
                            mondialisation. La solution contient plusieurs kits électroniques installés soit directement sur les machines principales
                            de productions ou bien au niveau des postes opérateurs. Grace à ces systèmes connectés, nous pouvons extraire en temps réel 
                            des statistiques et des informations jugées nécessaires pour superviser la chaine de production. Tous les Data collectés 
                            peuvent être visualisés en ligne via l’internent soit dans la salle de contrôle soit à l’extérieur de l’usine.                   
                          </p>
                          <button className="btn-telecharger-smartControl">
                            {/* {t("telecharger")} */}
                            Télécharger
                          </button>
                          <a href="https://www.smartlogger.tn/smart-monitoring/#/login">
                           <button className="btn-paltadm-smartControl">
                             Plateforme administrateur
                           </button>
                          </a>
                    </figcaption>
                  </Container>
                </figure>
              </Container>
            </div>) 
          }
        </div>
    )
}

export default Industrie
